import React from 'react';
import '../css/whatsappbtn.css';

class WhatsappBtn extends React.Component {
    state = {  } 
    render() { 
        return (
            <>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"/>
                <a href="https://wa.me/527714733271" className="float animate__animated animate__fadeInRight animate__slower" target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-whatsapp my-float"></i>
                </a>
            </>
        );
    }
}
 
export default WhatsappBtn;
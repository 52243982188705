import React from 'react';
import {Button} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

class Success extends React.Component {
    state = {  } 
    render() { 
        return (
            <>
                <div className="container formularioNuevoProyecto" hidden={this.props.values.step !== 4}>
                    <div className="mx-0 mx-lg-5 formulario p-2">
                                <div className="titulo mb-3 text-center">
                                    <h3>¡Listo!</h3>
                                </div>
                        <div className="row mb-3">
                            <div className="col-12 text-center">
                                <h5 className='my-5'>Hemos recibido su información. Nos pondremos en contacto con ustéd lo antes posible.</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <ThemeProvider theme={this.props.theme}>
                                    <Button href='https://www.garteel.com' fullWidth color='success' variant='contained'  className='btnFinFormulario'>Ir a Garteel.com</Button>
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
 
export default Success;
import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import Routes from './components/Routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/styles.css';
import 'animate.css';

function App() {
  return (
    <div>
        <Router>
          <Routes/>
        </Router>
    </div>
  );
}

export default App;
import React from 'react';
import {Button} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

class General extends React.Component {
    
    constructor(){
        super();
        this.state = {

        }
    }

    Continue = e => {
        e.preventDefault();
        this.props.nextStep();
      }

    render() { 
        return (
            <>
                <div className="container formularioNuevoProyecto" hidden={this.props.values.step !== 1}>
                    <div className="mx-0 mx-lg-5 formulario p-2">
                                <div className="titulo mb-3 text-center">
                                    <h3>General</h3>
                                </div>
                        <div className="row mb-3 justify-content-center">
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Nombre(s)</label>
                                <input type="text" className='form-control' onChange={this.props.handleChange('nombre')} value={this.props.values.nombre}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Apellido(s)</label>
                                <input type="text" className='form-control' onChange={this.props.handleChange('apellidos')} value={this.props.values.apellidos}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Número Telefónico</label>
                                <input type="tel" className='form-control' onChange={this.props.handleChange('telefono')} value={this.props.values.telefono}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Correo Electrónico</label>
                                <input type="email" className='form-control' onChange={this.props.handleChange('email')} value={this.props.values.email}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Dirección (De la propiedad a vender)</label>
                                <input type="text" className='form-control' onChange={this.props.handleChange('direccion')} value={this.props.values.direccion}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Referencias</label>
                                <input type="text" className='form-control' onChange={this.props.handleChange('referencia')} value={this.props.values.referencia}/>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-6 text-end justify-content-center">
                                <ThemeProvider theme={this.props.theme}>
                                    <Button fullWidth color='success' variant='contained' onClick={this.Continue}>Siguiente</Button>
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
 
export default General;
import React from 'react';
import {Button} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

class Terreno extends React.Component {
    state = {  } 

    Previous = e => {
        e.preventDefault();
        this.props.prevStep();
      }

    Continue = e => {
        e.preventDefault();
        this.props.nextStep();
      }

    render() { 
        return (
            <>
                <div className="container formularioNuevoProyecto" hidden={this.props.values.step !== 2}>
                    <div className="mx-0 mx-lg-5 formulario p-2">
                                <div className="titulo mb-3 text-center">
                                    <h3>Terreno</h3>
                                </div>
                        <div className="row mb-3 justify-content-center">
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Presupuesto (Pesos Mexicanos)</label>
                                    <select name="" id="" className='form-control' onChange={this.props.handleChange('presupuesto')} value={this.props.values.presupuesto}>
                                        <option value="">Seleccionar</option>
                                        <option value="Menos de $1,000,000">Menos de $1,000,000</option>
                                        <option value="$1,000,000 - $1,500,000">$1,000,000 - $1,500,000</option>
                                        <option value="$1,500,000 - $2,000,000">$1,500,000 - $2,000,000</option>
                                        <option value="$2,000,000 - $2,500,000">$2,000,000 - $2,500,000</option>
                                        <option value="$2,500,000 - $3,000,000">$2,500,000 - $3,000,000</option>
                                        <option value="$3,000,000 - $3,500,000">$3,000,000 - $3,500,000</option>
                                        <option value="$3,500,000 - $4,000,000">$3,500,000 - $4,000,000</option>
                                        <option value="$4,000,000 - $4,500,000">$4,000,000 - $4,500,000</option>
                                        <option value="$4,500,000 - $5,000,000">$4,500,000 - $5,000,000</option>
                                        <option value="$5,000,000 - $5,500,000">$5,000,000 - $5,500,000</option>
                                        <option value="$5,500,000 - $6,000,000">$5,500,000 - $6,000,000</option>
                                        <option value="$6,000,000 - $6,500,000">$6,000,000 - $6,500,000</option>
                                        <option value="$6,500,000 - $7,000,000">$6,500,000 - $7,000,000</option>
                                        <option value="Más de $7,000,000">Más de $7,000,000</option>
                                    </select>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>¿Cúando quiere que inicie su obra?</label>
                                    <input type="date" name="" id="" className='form-control' onChange={this.props.handleChange('fechaObra')} value={this.props.values.fechaObra}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Dirección</label>
                                <input type="text" className='form-control' onChange={this.props.handleChange('direccion')} value={this.props.values.direccion}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Referencias</label>
                                <input type="text" className='form-control' onChange={this.props.handleChange('referencia')} value={this.props.values.referencia}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Tipo de Terreno</label>
                                <select name="" id="" className='form-control' onChange={this.props.handleChange('tipoTerreno')} value={this.props.values.tipoTerreno}>
                                    <option value="">Seleccionar</option>
                                    <option value="Regular">Regular</option>
                                    <option value="Irregular">Irregular</option>
                                </select>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Metros Cuadrados</label>
                                <input type="number" className='form-control' onChange={this.props.handleChange('mtsCuadrados')} value={this.props.values.mtsCuadrados}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Medidas de Frente</label>
                                <input type="number" className='form-control' onChange={this.props.handleChange('medidasFrente')} value={this.props.values.medidasFrente}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Medidas de Largo</label>
                                <input type="number" className='form-control' onChange={this.props.handleChange('medidasLargo')} value={this.props.values.medidasLargo}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>¿El Terreno Está en Pendiente?</label>
                                <select name="" id="" className='form-control' onChange={this.props.handleChange('pendienteTerreno')} value={this.props.values.pendienteTerreno}>
                                    <option value="">Seleccionar</option>
                                    <option value="Si">Si</option>
                                    <option value="No">No</option>
                                </select>

                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Orientación/Coordenadas</label>
                                <input type="text" className='form-control' onChange={this.props.handleChange('orientacionCoordenadas')} value={this.props.values.orientacionCoordenadas}/>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-6">
                                <ThemeProvider theme={this.props.theme}>
                                    <Button fullWidth color='success' variant='contained' onClick={this.Previous}>Anterior</Button>
                                </ThemeProvider>
                            </div>
                            <div className="col-6 text-end">
                                <ThemeProvider theme={this.props.theme}>
                                    <Button fullWidth color='success' variant='contained' onClick={this.Continue}>Siguiente</Button>
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
 
export default Terreno;
import React from 'react';
import logo from '../../../img/logoBlanco.png';

class Header extends React.Component {
    state = {  } 

    prueba = () => {
        console.log(this.props.state);
    }

    render() { 
        return (
            <>
                <div className="container">
                    <div className='mx-0 mx-lg-5 d-flex justify-content-center'>
                        <a href="/" className='logo'>
                            <img src={logo} alt="" width="100%"/>
                        </a>
                    </div>
                </div>
            </>
        );
    }
}
 
export default Header;
import React from 'react';
import Header from './Header';
import { createTheme } from '@mui/material/styles';
import '../../../css/formulario.css';
import General from './General';
import Propiedad from './Propiedad';
import Caracteristicas from './Caracteristicas';
import Success from './Success';

class Formulario extends React.Component {

    constructor(){
        super();
        this.state = {
            step: 1,
            nombre: '',
            apellidos: '',
            telefono: '',
            email: '',
            direccion: '',
            referencia: '',

            tipoPropiedad: '',
            mtsCuadrados: '',
            mtsCuadradosConstruidos: '',
            noRecamaras: '',
            BanosCompletos: '',
            estacionamiento: '',
            anoConstruccion: '',
            precioPropiedad: '',

            caracteristicas: [],
        }
    }

    componentDidMount = () => {
        document.title = 'Formulario Vender - Garteel';
    }

    prevStep = () => {
        const { step } = this.state;
        this.setState({ step: step - 1 });
    }

    nextStep = () => {
        const { step } = this.state;
        this.setState({ step: step + 1 });
    }

    handleChange = input => e => {
        this.setState({ [input]: e.target.value });
      }

    handleChangePrecio = input => e => {
        this.setState({ [input]: e.value });
      }

    onChangeCaracteristicas = (valores) => {
        this.setState({
            caracteristicas: valores
        })
    }

    theme = createTheme({
        palette: {
          success: {
            main: '#2EBF70',
            contrastText: '#ffffff',
          }
        },
      });

    render() { 
        return (
            <>
                <div className='container my-4'>
                    <Header state={this.state}/>
                    <div className="container mb-4">
                        <div className="row text-center">
                            <div className="tprin col-12 py-3">
                                <h1 className='text-white'>Formulario: Vender Propiedad</h1>
                            </div>
                        </div>
                    </div>
                    <General
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        values={this.state}
                        theme = {this.theme}
                    />
                    <Propiedad
                        prevStep={this.prevStep}
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        handleChangePrecio={this.handleChangePrecio}
                        values={this.state}
                        theme = {this.theme}
                    />
                    <Caracteristicas
                        prevStep={this.prevStep}
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        values={this.state}
                        theme = {this.theme}
                        onChangeCaracteristicas = {this.onChangeCaracteristicas}
                    />
                    <Success
                        values={this.state}
                        theme = {this.theme}
                    />
                </div>
            </>
        )
    }
}
 
export default Formulario;
import React, { Component } from 'react';
import imgConstruccionBanner from '../../img/Inicio/construccion.png';
import imgFinalizadoBanner from '../../img/Inicio/finalizado.png';
import imgDisenoBanner from '../../img/Inicio/diseno.png';

class ConstruccionInicio extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    
    render() { 
        return ( 
            <div className="construccion p-5">
                <div className="row d-flex">
                    <div className="col-12 text-center reveal animate__animated pb-3">
                        <p style={{ fontSize: '34px', fontWeight: '200', textTransform: 'uppercase' }}>Espacios creados para ti</p>
                        <p style={{ fontSize: '25px', fontWeight: '100' }}>Hacemos proyectos a la medida de tus sueños.</p>
                    </div>
                    <div className="col-md-4 d-none d-md-block text-center reveal animate__animated">
                        <img src={imgDisenoBanner} alt="imgBanner" className='imgBanner1'/>
                    </div>
                    <div className="col-md-4 d-none d-md-block text-center reveal animate__animated animateDelay0_5s">
                        <img src={imgConstruccionBanner} alt="imgBanner" className='imgBanner1'/>
                    </div>
                    <div className="col-md-4 d-none d-md-block text-center reveal animate__animated animate__delay-1s">
                        <img src={imgFinalizadoBanner} alt="imgBanner" className='imgBanner1'/>
                    </div>
                    <div className="col-12 d-md-none d-flex justify-content-center crossfade reveal animate__animated">
                        <img src={imgDisenoBanner} alt="imgBanner" className='crossfadeIMG'/>
                        <img src={imgConstruccionBanner} alt="imgBanner" className='crossfadeIMG'/>
                        <img src={imgFinalizadoBanner} alt="imgBanner" className='crossfadeIMG'/>
                    </div>
                </div>
            </div>
         );
    }
}
 
export default ConstruccionInicio;
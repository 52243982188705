import React, { Component } from 'react';

class TecnologiaInicio extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() { 
        return (
            <div className="tecnologia p-4 p-md-5">
                <div className="row">
                    <div className="col-lg-6 text-center reveal animate__animated">
                        <iframe title='VR' className="ku-embed" frameBorder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowFullScreen scrolling="no" src="https://kuula.co/share/collection/7FFdf?logo=1&info=1&fs=1&vr=0&sd=1&gyro=0&thumbs=3&margin=15&alpha=0.60&inst=0&keys=0"></iframe>
                    </div>                    
                    <div className="col-lg-6 reveal animate__animated">
                        <div className="row px-1 px-md-5">
                            <div className="col-12 text-white reveal animate__animated">
                                <p className='m-0 mb-2 titulo'>TECNOLOGÍA</p>
                            </div>
                            <div className="col-12">
                                <p className='m-0 textoTEC'>Nuestros recorridos virtuales ofrecen una experiencia inmersiva que le permite ver su casa desde todos los ángulos, incluso antes de que se haya puesto la primera piedra. Con nuestra tecnología de vanguardia, puede caminar por los pasillos, ver la vista desde su ventana y sentirse como si ya estuviera en casa.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         );
    }
}
 
export default TecnologiaInicio;
import React from 'react';
import logoBlanco from '../img/logoBlanco.png';
import '../css/footer.css'

class Footer extends React.Component {
    constructor(){
        super();
        this.state = {
            style: []
        }
    }

    componentDidMount = () => {
        window.addEventListener("resize", this.getHeightFooter)
    }

    getHeightFooter = () => {
        var elemento = document.getElementById('footer');
        let styleInput = window.getComputedStyle(elemento);
        let height = styleInput.getPropertyValue('height');
        document.getElementsByTagName("body")[0].style.marginBottom = height;
    }

    render() { 
        return (
            <>
                <div className="container-fluid footer" id='footer' onLoad={this.getHeightFooter}>
                    <footer className="pt-3">
                        <div className="row pb-3">
                            <div className="col-12 text-center">                                
                                <img
                                    src={logoBlanco}
                                    className="logoBlancoFooter"
                                    alt="Garteel logo"
                                />
                            </div>
                        </div>
                        <div className="row pb-3">
                            <div className="col-4 col-md-2">
                                <ul className="nav">
                                    <li className="nav-item"><a href="/portafolio" className="nav-link p-0">Portafolio</a></li>
                                </ul>
                            </div>
                            <div className="col-4 col-md-2">
                                <ul className="nav">
                                    <li className="nav-item"><a href="/comprar" className="nav-link p-0">Comprar</a></li>
                                </ul>
                            </div>
                            <div className="col-4 col-md-2">
                                <ul className="nav">
                                    <li className="nav-item"><a href="/vender" className="nav-link p-0">Vender</a></li>
                                </ul>
                            </div>
                            <div className="col-4 col-md-2">
                                <ul className="nav">
                                    <li className="nav-item"><a href="/construir" className="nav-link p-0">Construir</a></li>
                                </ul>
                            </div>
                            <div className="col-4 col-md-2">
                                <ul className="nav">
                                    <li className="nav-item"><a href="/invertir" className="nav-link p-0">Invertir</a></li>
                                </ul>
                            </div>
                            <div className="col-4 col-md-2">
                                <ul className="nav">
                                    <li className="nav-item"><a href="/contacto" className="nav-link p-0">Contacto</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="row pb-2 pt-3" style={{ borderTop: '1px solid #1D1D1D' }}>
                            <div className="col-6 d-flex justify-content-start align-items-center">                                
                                <ul className="list-unstyled mb-0 d-flex justify-content-center align-items-center">
                                    <li><a className="link-light me-2" href="https://www.facebook.com/Garteel.Inmobiliaria" target="_blank" rel="noopener noreferrer"><i className="bi bi-facebook"></i></a></li>
                                    <li><a className='link-light mx-2' href="tel:+527714733271"><i className="bi bi-telephone-fill"></i></a></li>
                                </ul>
                            </div>
                            <div className="col-6 pt-0 pb-1 d-flex justify-content-end align-items-end">                                
                                <p className='m-0 copyright'>Copyright © {new Date().getFullYear()}</p>
                            </div>
                        </div>
                    </footer>
                </div>
            </>
        );
    }
}
 
export default Footer;
import React from 'react';
import NavBar from '../NavBar';
import Footer from '../Footer';
import WhatsappBtn from '../WhatsappBtn';
import imgVender from '../../img/vender.jpg';
import '../../css/vender.css';

class Vender extends React.Component {
    constructor() {
        super();
        this.state = {}
    }

    componentDidMount = () => {
        document.title = 'Vender - Garteel';
    }

    render() { 
        return ( 
            <>
                <NavBar/>
                <div className="container vender mt-4 pb-5 text-white">
                    <div className="text-center">
                        <h1 style={{ color: '#2EBF70' }}>¿QUIERES VENDER TU PROPIEDAD?</h1>
                        <h4>Compramos tu casa, departamento, terreno, local ó bodega.</h4>
                    </div>
                    <hr />
                    <div className="row mt-4 mt-lg-5">
                        <div className="col-12">
                            <h2 className='d-md-none mb-5 text-center' style={{ color: 'white', backgroundColor: '#2EBF70', borderRadius: '5px' }}>TE PAGAMOS EN 10 DÍAS HÁBILES.</h2>
                        </div>
                        <div className="col-12 d-flex d-lg-none justify-content-center align-items-center">
                            <img src={imgVender} alt="" width={'80%'} className='imgVender'/>
                        </div>
                        <div className="col-lg-6 mt-5 mt-md-0 px-5 d-flex justify-content-center align-items-center text-center">
                            <div>
                                <h2 className='d-none d-md-block mb-5' style={{ color: 'white', backgroundColor: '#2EBF70', borderRadius: '5px' }}>TE PAGAMOS EN 10 DÍAS HÁBILES.</h2>
                                <h4 className='d-none d-md-block'>Nuestro equipo técnico analizará tu propiedad y si llegamos a un acuerdo te pagamos en 10 días hábiles. Te pedimos contestes el siguiente formulario para iniciar el proceso de venta de tu propiedad.</h4>
                                <p className='' style={{ color: 'gray', fontSize: '12px' }}>Todas nuestras operaciones se realizan ante notario público.</p>
                                <p style={{ fontSize: '16px' }} className='d-md-none'>Nuestro equipo técnico analizará tu propiedad y si llegamos a un acuerdo te pagamos en 10 días hábiles. Te pedimos contestes el siguiente formulario para iniciar el proceso de venta de tu propiedad.</p>
                                <a href="/vender/formulario" className='btn btn-outline-success  mt-4'>Ir al Formulario</a>
                            </div>
                        </div>
                        <div className="col-6 d-lg-flex justify-content-center align-items-center d-none">
                            <img src={imgVender} alt="" width={'80%'} className='imgVender'/>
                        </div>
                    </div>
                </div>


                <Footer/>
                <WhatsappBtn/>
            </>
         );
    }
}
 
export default Vender;
import React from 'react';
import {Button} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

class Amenidades extends React.Component {
    state = {  } 

    Previous = e => {
        e.preventDefault();
        this.props.prevStep();
      }

    Continue = e => {
        e.preventDefault();
        this.props.nextStep();
      }

    render() { 
        return (
            <>
                <div className="container formularioNuevoProyecto" hidden={this.props.values.step !== 3}>
                    <div className="mx-0 mx-lg-5 formulario p-2">
                                <div className="titulo mb-3 text-center">
                                    <h3>Características</h3>
                                </div>
                        <div className="row mb-3 justify-content-center">
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Niveles</label>
                                <select name="" id="" className='form-control' onChange={this.props.handleChange('niveles')} value={this.props.values.niveles}>
                                    <option value="">Seleccionar</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Sótano</label>
                                <select name="" id="" className='form-control' onChange={this.props.handleChange('sotano')} value={this.props.values.sotano}>
                                    <option value="">Seleccionar</option>
                                    <option value="Si">Si</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Número de Recámaras</label>
                                <input type="number" className='form-control' onChange={this.props.handleChange('noRecamaras')} value={this.props.values.noRecamaras}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Número de vehiculos en la Cochera</label>
                                <input type="number" className='form-control' onChange={this.props.handleChange('noVehiculosCochera')} value={this.props.values.noVehiculosCochera}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Cochera Techada</label>
                                <select name="" id="" className='form-control' onChange={this.props.handleChange('cocheraTechada')} value={this.props.values.cocheraTechada}>
                                    <option value="">Seleccionar</option>
                                    <option value="Si">Si</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Caracaterísticas especiales: Sótano</label>
                                <input type="text" className='form-control' onChange={this.props.handleChange('a_sotano')} value={this.props.values.a_sotano}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Caracaterísticas especiales: Primer Nivel</label>
                                <input type="text" className='form-control' onChange={this.props.handleChange('a_primerNivel')} value={this.props.values.a_primerNivel}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Caracaterísticas especiales: Segundo Nivel</label>
                                <input type="text" className='form-control' onChange={this.props.handleChange('a_segundoNivel')} value={this.props.values.a_segundoNivel}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Caracaterísticas especiales: Tercer Nivel</label>
                                <input type="text" className='form-control' onChange={this.props.handleChange('a_tercerNivel')} value={this.props.values.a_tercerNivel}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Caracaterísticas especiales: Cuarto Nivel</label>
                                <input type="text" className='form-control' onChange={this.props.handleChange('a_cuartoNivel')} value={this.props.values.a_cuartoNivel}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Caracaterísticas especiales: Quinto Nivel</label>
                                <input type="text" className='form-control' onChange={this.props.handleChange('a_quintoNivel')} value={this.props.values.a_quintoNivel}/>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-6">
                                <ThemeProvider theme={this.props.theme}>
                                    <Button fullWidth color='success' variant='contained' onClick={this.Previous}>Anterior</Button>
                                </ThemeProvider>
                            </div>
                            <div className="col-6 text-end">
                                <ThemeProvider theme={this.props.theme}>
                                    <Button fullWidth color='success' variant='contained' onClick={this.Continue}>Siguiente</Button>
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
 
export default Amenidades;
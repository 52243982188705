import React from 'react';
import Header from './Header';
import General from './General';
import Terreno from './Terreno';
import Amenidades from './Amenidades';
import Diseno from './Diseno';
import Success from './Success';
import { createTheme } from '@mui/material/styles';
import '../../../css/formulario.css'

class Formulario extends React.Component {

    constructor(){
        super();
        this.state = {
            step: 1,
            nombre: '',
            apellidos: '',
            telefono: '',
            email: '',
            noIntegrantesFamilia: '',
            noMenores: '',
            noAdolescentes: '',
            noAdultosMayores: '',

            presupuesto: '',

            direccion: '',
            referencia: '',
            tipoTerreno: '',
            mtsCuadrados: '',
            medidasFrente: '',
            medidasLargo: '',
            pendienteTerreno: '',
            orientacionCoordenadas: '',

            niveles: '',
            sotano: '',
            noRecamaras: '',
            noVehiculosCochera: '',
            cocheraTechada: '',
            a_sotano: '',
            a_primerNivel: '',
            a_segundoNivel: '',
            a_tercerNivel: '',
            a_cuartoNivel: '',
            a_quintoNivel: '',

            caracteristicasDiseño: [],
            color: '',
            fachada: '',
            interiores: '',

            fechaObra: '',
        }
    }

    componentDidMount = () => {
        document.title = 'Formulario Construir - Garteel';
    }

    prevStep = () => {
        const { step } = this.state;
        this.setState({ step: step - 1 });
    }

    nextStep = () => {
        const { step } = this.state;
        this.setState({ step: step + 1 });
    }

    handleChange = input => e => {
        this.setState({ [input]: e.target.value });
      }

    onChangeCaracteristicasDiseno = (valores) => {
        this.setState({
            caracteristicasDiseño: valores
        })
    }

    theme = createTheme({
        palette: {
          success: {
            // Purple and green play nicely together.
            main: '#2EBF70',
            contrastText: '#ffffff'
          }
        },

      });

    render() { 
        document.body.style = 'bac#2EBF70kground: #f4f4f4;';
        return (
            <>
                <div className='container my-4'>
                    <Header state={this.state}/>
                    <div className="container mb-4">
                        <div className="row text-center">
                            <div className="col-12">
                                <h1 className='text-white'>Formulario: Crear Proyecto</h1>
                            </div>
                        </div>
                    </div>
                    <General
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        values={this.state}
                        theme = {this.theme}
                    />
                    <Terreno
                        prevStep={this.prevStep}
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        values={this.state}
                        theme = {this.theme}
                    />
                    <Amenidades
                        prevStep={this.prevStep}
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        values={this.state}
                        theme = {this.theme}
                    />
                    <Diseno
                        prevStep={this.prevStep}
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        values={this.state}
                        theme = {this.theme}
                        onChangeCaracteristicasDiseno = {this.onChangeCaracteristicasDiseno}
                    />
                    <Success
                        values={this.state}
                        theme = {this.theme}
                    />
                </div>
            </>
        )
    }
}
 
export default Formulario;
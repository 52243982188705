import React from 'react';
import {Button} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import NumberFormat from 'react-number-format';

class Propiedad extends React.Component {
    state = {  } 

    Previous = e => {
        e.preventDefault();
        this.props.prevStep();
      }

    Continue = e => {
        e.preventDefault();
        console.log(this.props.values);
        this.props.nextStep();
      }

    render() { 
        return (
            <>
                <div className="container formularioNuevoProyecto" hidden={this.props.values.step !== 2}>
                    <div className="mx-0 mx-lg-5 formulario p-2">
                                <div className="titulo mb-3 text-center">
                                    <h3>Propiedad</h3>
                                </div>
                        <div className="row mb-3 justify-content-center">
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Tipo de Propiedad</label>
                                <select name="" id="" className='form-control ' onChange={this.props.handleChange('tipoPropiedad')} value={this.props.values.tipoPropiedad}>
                                    <option value="">Seleccionar</option>
                                    <option value="Casa">Casa</option>
                                    <option value="Comercial">Comercial</option>
                                    <option value="Departamento">Departamento</option>
                                    <option value="Industrial">Industrial</option>
                                    <option value="Terreno">Terreno</option>
                                </select>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Metros Cuadrados Terreno</label>
                                <input type="number" className='form-control' onChange={this.props.handleChange('mtsCuadrados')} value={this.props.values.mtsCuadrados}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Metros Cuadrados Construidos</label>
                                <input type="number" className='form-control' onChange={this.props.handleChange('mtsCuadradosConstruidos')} value={this.props.values.mtsCuadradosConstruidos}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Número de Recamaras</label>
                                <input type="number" className='form-control' onChange={this.props.handleChange('noRecamaras')} value={this.props.values.noRecamaras}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Baños Completos</label>
                                <input type="number" className='form-control' onChange={this.props.handleChange('BanosCompletos')} value={this.props.values.BanosCompletos}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Lugares de Estacionamientos</label>
                                <input type="number" className='form-control' onChange={this.props.handleChange('estacionamiento')} value={this.props.values.estacionamiento}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Año de Construcción</label>
                                <input type="number" className='form-control' onChange={this.props.handleChange('anoConstruccion')} value={this.props.values.anoConstruccion}/>
                            </div> 
                            <div className="col-12 col-md-6 col-lg-5">
                                <label>Precio aproximado de su propiedad</label>
                                <NumberFormat thousandSeparator={true} prefix={'$'} className='form-control' value={this.props.values.precioPropiedad} onValueChange={this.props.handleChangePrecio('precioPropiedad')}/>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-6 ">
                                <ThemeProvider theme={this.props.theme}>
                                    <Button fullWidth color='success' variant='contained' onClick={this.Previous}>Anterior</Button>
                                </ThemeProvider>
                            </div>
                            <div className="col-6 text-end ">
                                <ThemeProvider theme={this.props.theme}>
                                    <Button fullWidth color='success' variant='contained' onClick={this.Continue}>Siguiente</Button>
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
 
export default Propiedad;
import React from 'react';
import img from '../img/notFoundRoute.svg';
import NavBar from './NavBar';
import Footer from './Footer';

class NotFoundRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() { 
        return ( 
            <>
                <NavBar/>
                <div className="container text-center mt-5" >
                    <div className="row mt-5">
                        <div className="col">
                            <img src={img} alt="" width="40%"/>
                        </div>
                    </div>
                    <div className="row mt-2 pb-5">
                        <div className="col">
                            <h2 className='text-white'>LA PÁGINA QUE BUSCA NO EXISTE</h2>
                        </div>
                    </div>
                </div>
                <Footer/>
            </>
         );
    }
}
 
export default NotFoundRoute;
import React from 'react';
import NavBar from '../NavBar';
import WhatsappBtn from '../WhatsappBtn';
import Footer from '../Footer';
import imgContrsuir from '../../img/construir.jpg';
import '../../css/construir.css'

class Contruir extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
        document.title = 'Construir - Garteel';
    }
    
    render() { 
        return ( 
            <>
                <NavBar/>

                <div className="container mt-4 pb-5 construir text-white">
                    <div className="text-center">
                        <h1 style={{'color' : '#2EBF70', textAlign : 'center'}}>ESPACIOS CREADOS PARA TI</h1>
                        <h4>Empecemos un nuevo proyecto hecho a tu medida.</h4>
                    </div>
                    <hr />
                    <div className="row mt-4 mt-lg-5">
                        <div className="col-12">
                            <h2 className='d-md-none mb-5 text-center' style={{ color: 'white', backgroundColor: '#2EBF70', borderRadius: '5px' }}>DISEÑAMOS Y CONSTRUIMOS PARA TÍ.</h2>
                        </div>
                        <div className="col-12 d-flex d-lg-none justify-content-center align-items-center">
                            <img src={imgContrsuir} alt="" width={'80%'} className='imgConstruir'/>
                        </div>
                        <div className="col-lg-6 mt-5 mt-md-0 px-5 d-flex justify-content-center align-items-center text-center">
                            <div>
                                <h2 className='d-none d-md-block mb-5' style={{ color: 'white', backgroundColor: '#2EBF70', borderRadius: '5px', fontSize: '28px' }}>DISEÑAMOS Y CONSTRUIMOS PARA TÍ.</h2>
                                <h4 className='d-none d-md-block' style={{ textAlign: 'justify' }}>En Garteel estamos comprometidos con realizar proyectos funcionales y atractivos para nuestros clientes. 
                                Contesta el siguiente formulario sin compromiso y te enviaremos un presupuesto para la elaboración de tu proyecto.</h4>
                                <p style={{ fontSize: '16px', textAlign:'justify' }} className='d-md-none'>CEn Garteel estamos comprometidos con realizar proyectos funcionales y atractivos para nuestros clientes. 
                                Contesta el siguiente formulario sin compromiso y te enviaremos un presupuesto para la elaboración de tu proyecto.</p>
                                <a href="/construir/formulario" className='btn btn-outline-success mt-4'>Ir al Formulario</a>
                            </div>
                        </div>
                        <div className="col-6 d-lg-flex justify-content-center align-items-center d-none">
                            <img src={imgContrsuir} alt="" width={'80%'} className='imgConstruir'/>
                        </div>
                    </div>
                </div>

                <WhatsappBtn/>
                <Footer/>
            </>
         );
    }
}
 
export default Contruir;